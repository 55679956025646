import React from "react";
import StandardPageTemplate from "../../../templates/standard-page";
import PageWrapper from "Components/core/PageWrapper";
import { Link } from "gatsby";
import * as Text from "Components/text";

import Heading from "Components/Heading";
import FeatureTable from "Components/FeatureTable";
import PriceBlockSmall from "Components/PriceBlockSmall";
import BrainImg from "Images/index-company-pillars.svg";
import AmyleeSphere from "Images/amylee-amos-sphere.png";
import BrainIcon from "Images/brain-white.svg";

const PAGE_PROPS = {
  title: "Hope in the Fight Against Alzheimer’s",
  desc:
    "Our program is designed to empower you with the tools necessary to enact the most promising treatment for Alzheimer's disease and dementia in your life, or the life of your loved one.",
};

const LP_Emotion_Page = () => {
  return (
    <StandardPageTemplate props={PAGE_PROPS}>
      <PageWrapper>
        <Heading
          text="Hope in the Fight Against Alzheimer’s"
          subtitle="An All New Program"
        />
        <Text.P>
          Since you’re here, my guess is that you’re either a caregiver to
          someone living with Alzheimer’s disease or you’re interested in
          Alzheimer’s prevention for you or a loved one. Whatever the case, I’m
          sure you’ve been inundated by plenty of statistics, research, and
          resources. You’ve heard numbers that scare you, such as the fact that
          six million people have Alzheimer’s Disease right now. Knowing this
          information doesn’t make it any easier to know where to begin. I
          should know. My family and I were in your shoes only a few years ago.
          It’s scary, you feel hopeless, and you’re wondering what programs and
          techniques you can trust will help you see results. Rest assured,
          every person in your situation feels this way. Alzheimer’s can upend
          lives and cause pain to entire families. When my grandmother suffered,
          there was no treatment available <strong>but now, there is.</strong>
        </Text.P>
        <div class="w-auto md:w-5/12 shadow-lg rounded-lg pl-8 pr-4 py-4 mb-12 flex flex-row items-center">
          <div>
            <img class="w-24" src={AmyleeSphere} alt="" />
          </div>
          <div class="pl-4">
            <span class="text-base text-gray-500 block mb-0">
              Amylee Amos PhD, RDN, IFMCP
            </span>
            <span class="text-sm text-purple-800 block mb-0">
              Founder, Amos Institute
            </span>
          </div>
        </div>
        <Text.H2>Our Cognitive Health Program</Text.H2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <Text.P>
              The Amos Institute has transformed the latest scientific research
              for fighting and preventing cognitive diseases into a
              comprehensive program you can implement from your home. This
              program is designed to empower you with the methods, tools, and
              information necessary to enact the most promising treatments for
              Alzheimer’s disease and dementia into your life or the life of a
              loved one.
            </Text.P>
            <Text.P>
              Coupled with support from our team of highly trained nutrition and
              health specialists, our Cognitive Health Program is not only
              highly impactful, but it’s the most accessible program of its
              kind. From day one, you’ll start making significant changes to
              your lifestyle, putting you on the path toward your best possible
              health outcomes.
            </Text.P>
          </div>
          <div>
            <img
              class="mx-auto pt-0 lg:pt-8 w-80 lg:w-auto"
              src={BrainImg}
              alt=""
            />
          </div>
        </div>
        <Text.H2>Life Changing Results</Text.H2>
        <Text.P>
          Built upon{" "}
          <Link to={"/what-is-the-bredesen-protocol/"}>
            extensive evidence-based research
          </Link>
          , our Cognitive Health Program is a therapeutic, personalized program
          that identifies, targets, and treats the root cause of the cognitive
          decline from Alzheimer’s disease. Not only does our Cognitive Health
          Program improve markers of cognitive function including memory,
          attention, and alertness, but our clients also experience improvements
          in inflammatory status, reduced oxidative stress, improved glycemic
          control, and reduced total toxic load.
        </Text.P>
        <div class="w-auto md:w-8/12 mx-auto shadow-lg rounded-lg pl-8 pr-4 py-4 mb-12 flex flex-row items-center bg-purple-800">
          <div>
            <img class="sm:w-24 w-40" src={BrainIcon} alt="" />
          </div>
          <div class="pl-6">
            <span class="text-base block mb-0 text-white">
              Results of a recent clinical trial show that{" "}
              <strong class="text-white text-lg">84%</strong> of patients
              exhibit improvement in their cognition when following the
              methodologies implimented through our program.
            </span>
          </div>
        </div>
        <Text.P>
          By using the Bredesen Protocol, multiple personalized strategies, such
          as changes in nutrition, targeted supplementation, sleep hygiene
          optimization, stress management, physical exercise, brain training,
          and related modalities are used to improve cognition and reverse the
          cognitive decline and Alzheimer’s disease. In other words, instead of
          masking symptoms (like prescription drugs do), our program addresses
          the root cause of the disease. This approach has been highly effective
          and has been used in over thirteen countries, including throughout the
          United States.
        </Text.P>
        <FeatureTable />
        <Text.H2>The Best Time to Start is Right Now</Text.H2>
        <Text.P>
          Our Cognitive Health Program was designed to provide immediate aid for
          anyone who is dealing with or trying to prevent Alzheimer's Disease
          and Dementia as well as anyone who is taking care of a loved one with
          these diseases. The cost of Alzheimer's disease can be massive, but
          it's the Amos Institute's mission to provide services that are
          affordable and accessible for everyone while being capable of
          transformative results. It only takes a few minutes to get started,
          signup now and begin your journey to better cognitive health.
        </Text.P>
        <PriceBlockSmall />
      </PageWrapper>
    </StandardPageTemplate>
  );
};

export default LP_Emotion_Page;
